import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { Box, Link, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AllReviewLogs = () => {
    const [reviewLogs, setReviewLogs] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(100); // Set default page size to 10
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const navigate = useNavigate();
    
        useEffect(() => {
            const token = localStorage.getItem('token');    
            if (!token) {
            console.log("No token found, redirecting to login");
            navigate("/login");
            }
        }, [])

    useEffect(() => {
        fetchReviewLogs(page, pageSize);
        loadColumnVisibility()
    }, [page, pageSize]);

    const fetchReviewLogs = async (page, limit) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}reviewLog/review-logs`, {
                params: { page, limit },
                headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
            });
            setReviewLogs(response.data.reviewLogs);
            setTotalPages(response.data.totalPages);
            setTotalItems(response.data.totalItems);
        } catch (error) {
            toast.error('Failed to fetch review logs');
            console.error('Error fetching review logs:', error);
        }
    };

    const loadColumnVisibility = () => {
        const storedVisibility = localStorage.getItem('reviewLogsColumnVisibility');
        if (storedVisibility) {
            setColumnVisibilityModel(JSON.parse(storedVisibility));
        }
    };

    const saveColumnVisibility = (model) => {
        console.log("save cloumn Visibility")
        localStorage.setItem('reviewLogsColumnVisibility', JSON.stringify(model));
        setColumnVisibilityModel(model);
    };


    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const columns = [
        { field: 'reviewText', headerName: 'Review Text', flex: 3 },
        { field: 'reviewType', headerName: 'Review Type', flex: 1 },
        { field: 'profileName', headerName: 'Account Name', flex: 2 },
        {
            field: 'rating',
            headerName: 'Rating',
            flex: 2,
            renderCell: (params) => (
                <Box display="flex" alignItems="center">
                    {Array.from({ length: 5 }, (_, index) => (
                        <StarIcon key={index} style={{ color: index < params.value ? '#ffb400' : '#e4e5e9' }} />
                    ))}
                </Box>
            ),
        },
        {
            field: 'reviewLink',
            headerName: 'Review Link',
            flex: 3,
            renderCell: (params) => (
                <Link href={params.value} color={'white'} target="_blank" rel="noopener noreferrer" underline="hover">
                    {params.value}
                </Link>
            ),
        },
        {
            field: 'isPublic',
            headerName: 'Public',
            flex: 1,
        },
        { field: 'runTime', headerName: 'Run Time', flex: 2,  
            valueGetter: ({ value }) => {
            const date = new Date(value);
            return `${String(date.getUTCFullYear())}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')} ` + 
                   `${String(date.getUTCHours()).padStart(2, '0')}:${String(date.getUTCMinutes()).padStart(2, '0')}:${String(date.getUTCSeconds()).padStart(2, '0')}`;
        },
    }
    ];

    const rows = reviewLogs.map((log) => ({
        id: log._id,
        reviewText: log.reviewText,
        rating: log.rating,
        reviewLink: log.reviewLink,
        runTime: log.runTime,
        reviewType: log.reviewType,
        isPublic: log.isPublic,
        profileName: log.account_id.profileName,
    }));

    return (
        <Box sx={{ width: '100%', height: '80vh', p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Review Logs
            </Typography>

            <Box sx={{ height: '85vh', width: '95%', margin: 'auto' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 20, 50]} // Add page size options
                    pagination
                    paginationMode="server"
                    rowCount={totalItems}
                    page={page - 1} // DataGrid uses 0-based index for pagination
                    onPaginationModelChange={(newPaginationModel) => handlePageChange(newPaginationModel.page + 1)} // Handle page changes
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)} // Handle page size changes
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) => saveColumnVisibility(newModel)}
                    sx={{
                        fontFamily: 'Poppins, sans-serif',
                        '& .MuiDataGrid-columnHeaders': {
                            color: 'white',
                            fontSize: '1.25rem',
                            alignSelf: "flex-end"
                        },
                        '& .MuiButtonBase-root': {
                            color: 'white',
                        },
                        '& .MuiDataGrid-row': {
                            backgroundColor: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#2D3748',
                            },
                        },
                        '& .MuiDataGrid-footerContainer': {
                            backgroundColor: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)',
                            color: 'white',
                        },
                        '& .MuiTablePagination-selectLabel, & .MuiTablePagination-select, & .MuiTablePagination-displayedRows': {
                            color: 'white',
                        },
                        '& .MuiDataGrid-cell': {
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                        },
                        '& .super-app-theme--header': {
                            '.MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold',
                                textAlign: 'center',
                                flex: 1,
                                justifyContent: 'center',
                            },
                        },
                        '& .MuiCheckbox-root svg': {
                            color: '#2b9fdb',
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export default AllReviewLogs;

