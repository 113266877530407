import React, { useState, useEffect } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { Rating } from '@mui/material';  // Import Rating component
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

const CreateReview = ({ render, setRender }) => {
    const [open, setOpen] = useState(false);
    const [reviewText, setReviewText] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [location, setLocation] = useState('');
    const [rating, setRating] = useState(0);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [attachCategory, setAttachCategory] = useState(false);

    const [errors, setErrors] = useState({}); // For handling validation errors

    const URL = process.env.REACT_APP_BASE_URL;
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"));

    // Open/close modal handlers
    const handleClickOpen = () => {
        setOpen(true);
        fetchCategories();  // Fetch categories when opening the modal
    };

    const handleClose = () => {
        setOpen(false);
        setReviewText('');
        setLocation('');
        setSearchKeyword('')
        setRating(0);  // Reset rating to 0
        setSelectedCategory('');
        setAttachCategory(false);  // Reset category attachment checkbox
    };

    const validateFields = () => {
        let tempErrors = {};
        let errorMessages = []; // Array to hold error messages

        if (rating === 0) {
            tempErrors.rating = "Rating is required";
            errorMessages.push("Rating is required");
        }
        if (!searchKeyword.trim()) {
            tempErrors.searchKeyword = "Search keyword is required";
            errorMessages.push("Search keyword is required");
        }

        // For category attachment validation, only check if the checkbox is checked
        if (attachCategory && !selectedCategory) {
            tempErrors.selectedCategory = "Please select a category or uncheck 'Attach to Category'";
            errorMessages.push("Please select a category or uncheck 'Attach to Category'");
        }

        setErrors(tempErrors);
        return errorMessages.length === 0 ? true : errorMessages;  // Return error messages if there are errors
    };
    // Fetch categories for the dropdown
    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${URL}review-category/all-categories`,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setCategories(response.data);
        } catch (error) {
            toast.error('Failed to fetch categories');
            console.error('Error fetching categories:', error);
        }
    };

    // Handle form submission
    const handleSubmit = async () => {
        const validationErrors = validateFields();

        if (validationErrors !== true) {
            // Show each validation error in a toast
            validationErrors.forEach((error) => {
                toast.error(error);  // Display each error message in the toast
            });
            return;
        }
        const reviewData = {
            reviewText,
            location,
            rating,
            searchKeyword,
        };

        // If the category checkbox is checked and a category is selected, include the category in the payload
        if (attachCategory && selectedCategory) {
            reviewData.category = selectedCategory;
        }

        try {
            const response = await axios.post(`${URL}review/create`, reviewData ,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (ToasterToggle) {
                toast.success(response.data.message || 'Review created successfully');
            }
            setRender((prev) => !prev);  // Re-render the reviews list
            handleClose();
        } catch (error) {
            toast.error('Failed to create review');
            console.error('Error creating review:', error);
        }
    };

    return (
        <div>
            <Button style={{ margin: "2rem 3rem" }} variant="contained" color="primary" onClick={handleClickOpen}>
                Create Review
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create a New Review</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Review Text (Optional)"
                        fullWidth
                        variant="outlined"
                        value={reviewText}
                        onChange={(e) => setReviewText(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Search Keyword"
                        fullWidth
                        variant="outlined"
                        value={searchKeyword}
                        onChange={(e) => setSearchKeyword(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Location (Optional)"
                        fullWidth
                        variant="outlined"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                    />

                    {/* Rating component to select 1-5 stars */}
                    <div style={{ margin: "1rem 0" }}>
                        <Typography component="legend">Rating</Typography>
                        <Rating
                            name="simple-controlled"
                            value={rating}
                            onChange={(event, newValue) => {
                                setRating(newValue);  // Set rating to 1-5 value
                            }}
                            precision={1}  // Only full stars, no half stars
                        />
                    </div>

                    {/* Checkbox to attach category */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={attachCategory}
                                onChange={(e) => setAttachCategory(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Attach to Category"
                    />

                    {/* Dropdown to select category (shown only if checkbox is checked) */}
                    {attachCategory && (
                        <TextField
                            select
                            label="Select Category"
                            fullWidth
                            margin="dense"
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                        >
                            {categories.map((category) => (
                                <MenuItem key={category._id} value={category._id}>
                                    {category.categoryName}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default CreateReview;

