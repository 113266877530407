import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, List, ListItem, ListItemText, Divider, ListItemIcon } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const AllVMs = () => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();
    const user = localStorage.getItem("user");
    const [vms, setVMs] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [accounts, setAccounts] = useState({});
    const [loading, setLoading] = useState(false);
    const [accountLoading, setAccountLoading] = useState({});
    const [totalAccounts, setTotalAccounts] = useState(0);

    useEffect(() => {
        const token = localStorage.getItem('token');    

        if (!token) {
        console.log("No token found, redirecting to login");
        navigate("/login");
        } else {
            setLoading(true); 
            axios.get(`${baseURL}vm/getAllVMs`,{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then((res) => {
                    setVMs(res.data.vms);
                    setTotalAccounts(res.data.totalAccounts)
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [user, navigate, baseURL]);

    const handleChange = (panel) => async (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);

        if (isExpanded && !accounts[panel]) {
            setAccountLoading(prevLoading => ({ ...prevLoading, [panel]: true }));
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${baseURL}vm/getAccountNamesByVMId/${panel}`,{
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setAccounts(prevAccounts => ({ ...prevAccounts, [panel]: response.data }));
            } catch (error) {
                console.error('Error fetching account names:', error);
            } finally {
                setAccountLoading(prevLoading => ({ ...prevLoading, [panel]: false }));
            }
        }
    };

    return (
        <Box sx={{ flexGrow: 1, color: 'white', mt: 10 }}>
            <Typography variant="h3" sx={{ mb: 10 }}> VMs </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="h6" sx={{ color: 'white' }}>VM Name</Typography>
                <Typography variant="h6" sx={{ color: 'white' }}>Account Count</Typography>
                <Typography variant="h6" sx={{ color: 'white' }}>Expand</Typography>
            </Box>
            <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress sx={{ color: 'blue' }} />
                    </Box>
                ) : (
                    vms.map((vm, index) => (
                        <Accordion key={vm._id} expanded={expanded === vm._id} onChange={handleChange(vm._id)} sx={{ bgcolor: 'black', color: 'white' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`${vm._id}-content`}
                                id={`${vm._id}-header`}
                                sx={{ background: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)', color: 'white' }}
                            >
                                <Typography sx={{ width: '50%', flexShrink: 0 }}>{`${vm.name} (${index + 1})`}</Typography>
                                <Typography sx={{ color: 'white' }}>
                                    {vm.accountCount}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ background: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)', color: 'white' }}>
                                {accountLoading[vm._id] ? (
                                    <CircularProgress sx={{ color: 'blue' }} />
                                ) : accounts[vm._id] ? (
                                    <List sx={{ width: '100%', background: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)' }}>
                                        {accounts[vm._id].map((accountName, index) => (
                                            <React.Fragment key={index}>
                                                <ListItem >
                                                    <ListItemIcon>
                                                        <FiberManualRecordIcon sx={{ color: 'white', fontSize: 'small' }} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={`${accountName}`} sx={{ color: 'white', ml: 2 }} />
                                                </ListItem>
                                                {index < accounts[vm._id].length - 1 && <Divider sx={{ bgcolor: 'grey' }} />}
                                            </React.Fragment>
                                        ))}
                                    </List>
                                ) : (
                                    <Typography>No accounts found</Typography>
                                )}
                            </AccordionDetails>

                        </Accordion>
                    ))
                )}
            </div>
            <Typography textAlign={"end"} marginRight={2}>Total Accounts In VMS : {totalAccounts}</Typography>
        </Box>
    );
};

export default AllVMs;

