import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import DataTable from '../DataTable';
import SpecificReviewLogsCategoriesTable from './SpecificReviewLogsCategoriesTable';
import SpecificReviewLogsGMBLinksTable from './SpecificReviewLogsGMBLinksTable';

const SpecificReviewLogsCategoriesMain = ({ render, setRender, data, rows, setPassSelectedCategoryId, columns }) => {
    const [rowsData, setRowsData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [gmbCategories, setGmbCategories] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

    useEffect(() => {
        setRowsData(rows);
        fetchCategories();
        loadColumnVisibility();
    }, [render]);

    // useEffect(() => {
    //     handleCategorySelect(selectedCategoryId);
    // }, [render]);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}pbs-category/all`,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setGmbCategories(response.data);
        } catch (error) {
            toast.error('Failed to fetch categories');
        }
    };

    const fetchGMBByCategory = async (categoryId) => {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}pbs-category/pbs-links/${categoryId}`;
            const response = await axios.get(url,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setCategories(response.data?.links);
        } catch (error) {
            toast.error('Failed to fetch reviews by category');
        }
    };

    const fetchReviewsByCategory = async (categoryId, page = 1, limit = 100) => {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}pbs-category/review-logs/${categoryId}?page=${page}&limit=${limit}`;
            const response = await axios.get(url,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setTotalPages(response.data.totalPages);
            setCurrentPage(response.data.currentPage);
            setTotalItems(response.data.totalItems);

            const rows = response?.data?.reviewLogs.map((log) => ({
                id: log._id,
                reviewText: log.reviewText,
                rating: log.rating,
                reviewLink: log.reviewLink,
                runTime: log.runTime,
                isPublic: log.isPublic,
                profileName: log.account_id.profileName,
                gmail: log.account_id?.gmail?.gmail || "",
            }));
        
            setRowsData(rows);
        } catch (error) {
            toast.error('Failed to fetch reviews by category');
        }
    };

    const setSearchReview = (reviewData) => {
        setTotalPages(reviewData.totalPages);
        setCurrentPage(reviewData.currentPage);
        setTotalItems(reviewData.totalItems);

        const rows = reviewData?.data?.specificReviewLogs.map((log) => ({
            id: log._id,
            reviewText: log.reviewText,
            rating: log.rating,
            reviewLink: log.reviewLink,
            runTime: log.runTime,
            isPublic: log.isPublic,
            profileName: log.account_id.profileName,
            gmail: log.account_id?.gmail?.gmail || "",
            categoryName: log.gmb_id?.categoryName || ""
        }));

        setRowsData(rows);

    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        fetchReviewsByCategory(selectedCategoryId, newPage);
    };

    const handleCategorySelect = (categoryId) => {
        setCurrentPage(1);
        setSelectedCategoryId(categoryId);
        setPassSelectedCategoryId(categoryId);
        fetchReviewsByCategory(categoryId, 1);
    };

    const loadColumnVisibility = () => {
        const storedVisibility = localStorage.getItem('specificReviewLogsColumnVisibility');
        if (storedVisibility) {
            setColumnVisibilityModel(JSON.parse(storedVisibility));
        }
    };

    const saveColumnVisibility = (model) => {
        localStorage.setItem('specificReviewLogsColumnVisibility', JSON.stringify(model));
        setColumnVisibilityModel(model);
    };

    const ConfirmHandleDelete = (deleteData) => {
        toast.info('Delete isnt Implemented Yet');
    };

    const handleGMBCategorySelect = (categoryId) => {
        fetchGMBByCategory(categoryId);
    };

    return (
        <div>
            {/* <SearchReview setSearchReview={setSearchReview} /> */}
            <div style={{ marginTop:"3rem" , display: "flex", flexDirection: "row", width: "100%" }}>
                <div style={{ flex: 2, marginRight: "20px" }}>
                    <SpecificReviewLogsGMBLinksTable onCategorySelect={handleGMBCategorySelect} categories={gmbCategories} />      
                    <SpecificReviewLogsCategoriesTable onCategorySelect={handleCategorySelect} categories={categories} />
                 </div>
                <div style={{ flex: 10 }}>
                    {data.length > 0 && rowsData.length > 0 ? (
                        <DataTable rows={rowsData} columns={columns} handlePageChange={handlePageChange} onDeleteSelected={ConfirmHandleDelete} currentPage={currentPage} totalPages={totalPages} totalItems={totalItems} saveColumnVisibility={saveColumnVisibility} columnVisibilityModel={columnVisibilityModel} />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default SpecificReviewLogsCategoriesMain;

