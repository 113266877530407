import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import SearchKeyword from '../components/keyword/SearchKeyword';
import { Button, IconButton } from '@mui/material';
import { RiDeleteBin6Line } from 'react-icons/ri';
import ConfirmDeleteModal from '../components/ConfirmDeleteModal';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const AllKeywords = ({ render, setRender }) => {
    const [keywords, setKeywords] = useState([]);
    const [page, setPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [limit] = useState(100);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [delData, setDeleteData] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem('token');    
        if (!token) {
        console.log("No token found, redirecting to login");
        navigate("/login");
        }
    }, [])

    useEffect(() => {
        fetchKeywords(page);
        loadColumnVisibility();
    }, [render]);

    const fetchKeywords = async (pageNumber) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}keyword/all-Keywords`, {
                params: {
                    page: pageNumber + 1,
                    limit,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setKeywords(response.data.keywords);
            setTotalItems(response.data.totalItems);
        } catch (error) {
            console.error('Failed to fetch keywords:', error);
        }
    };

    const setSearchKeyword = (keywordData) => {
        setTotalItems(keywordData.totalItems);
        setKeywords(keywordData.keywords)
    }

    const handlePageChange = (params) => {
        setPage(params.page);
        fetchKeywords(params.page)
    };

    const loadColumnVisibility = () => {
        const storedVisibility = localStorage.getItem('keywordColumnVisibility');
        if (storedVisibility) {
            setColumnVisibilityModel(JSON.parse(storedVisibility));
        }
    };

    const saveColumnVisibility = (model) => {
        localStorage.setItem('keywordColumnVisibility', JSON.stringify(model));
        setColumnVisibilityModel(model);
    };

    const columns = [
        { field: '_id', headerName: 'ID', flex: 1, editable: true },
        {
            field: 'keyword', headerName: 'Keyword', flex: 1, editable: true
        }
    ];

    const handleBulkDelete = async (deleteData) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}keyword/bulkdelete`, { ids: deleteData },{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            toast.success(response.data.message || 'Selected keywords deleted successfully');
            setRender(prev => !prev);
        } catch (error) {
            toast.error(error.response.data.message || 'Failed to delete selected keywords');
        }
    };



    const ConfirmHandleDelete = (deleteData) => {
        setIsDeleteModalOpen(true);
        setDeleteData(deleteData);
    };

    const handleDeleteConfirm = () => {
        setIsDeleteModalOpen(false);
        handleBulkDelete(delData);
    };

    return (
        <div style={{ padding: '0.2rem' }}>
            <SearchKeyword setSearchKeyword={setSearchKeyword} />
            <div style={{ width: '100%', height: '65vh' }}>
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <DataGrid
                    rows={keywords}
                    columns={columns}
                    pageSize={limit}
                    rowCount={totalItems}
                    pagination
                    paginationMode="server"
                    getRowId={(row) => row._id}
                    onPaginationModelChange={(params) => handlePageChange(params)}
                    checkboxSelection
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}

                    page={page}
                    rowsPerPageOptions={[limit]}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) => saveColumnVisibility(newModel)}
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)',
                            color: 'white',
                            fontSize: '1.25rem',
                            textAlign: 'center',
                        },
                        '& .MuiButtonBase-root': {
                            color: 'white',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            justifyContent: 'center',
                        },
                        '& .MuiDataGrid-row': {
                            backgroundColor: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#334a8d', // Darker blue on hover
                            },
                        },
                        '& .MuiDataGrid-cell': {
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            color: 'white',
                        },
                        '& .MuiDataGrid-footerContainer': {
                            backgroundColor: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)',
                            color: 'white',
                        },
                        '& .MuiTablePagination-root': {
                            color: 'white',
                        },
                        '& .MuiPaginationItem-root': {
                            color: 'white',
                        },
                    }}
                />
            </div>
            <Button
                variant="contained"
                color="error"
                onClick={() => ConfirmHandleDelete(rowSelectionModel)}
                disabled={rowSelectionModel.length === 0}
                sx={{ mt: 2 }}
            >
                Delete Selected
            </Button>
            <ConfirmDeleteModal
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                onConfirm={handleDeleteConfirm}
                message="Are you sure you want to delete these Keywords?"
            />
        </div>
    );
};

export default AllKeywords;

