import React, { useEffect, useState } from 'react';
// import DataTable from '../components/DataTable';
import { CircularProgress, Typography, IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import axios from "axios";
import Circular from '../components/Circular';
// import Importquestion from '../components/Importquestion';
import { useNavigate } from 'react-router-dom';
import { CiEdit } from "react-icons/ci";
import { ToastContainer, toast } from 'react-toastify';

import { CgMoreVertical } from "react-icons/cg";
import EditQuestionModal from '../components/question/EditQuestionModal';
import CreateQuestion from '../components/question/CreateQuestion';
import QuestionCategoriesMain from '../components/question/QuestionCategoriesMain';
import ImportQuestion from '../components/question/ImportQuestion';

const AllQuestion = () => {
    const URL = process.env.REACT_APP_BASE_URL;
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"));
    const [data, setData] = useState([]);
    const [render, setRender] = useState(false);
    const navigate = useNavigate();
    const [passSelectedCategoryId, setPassSelectedCategoryId] = useState('all');
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentQuestionData, setCurrentQuestionData] = useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [assignMultipleToCategory, setAssignMultipleToCategoty] = useState([]);
    const [assignCategoryModalOpen, setAssignCategoryModalOpen] = useState(false);
    const [assignCategoryModalBulkOpen, setAssignCategoryModalBulkOpen] = useState(false);

    const [selectedQuestionForCategory, setSelectedQuestionForCategory] = useState(null);


    useEffect(() => {
        const token = localStorage.getItem('token');    
        if (!token) {
        console.log("No token found, redirecting to login");
        navigate("/login");
        }
    }, [])

    const handleOpenEditModal = (questionData) => {
        setCurrentQuestionData(questionData);
        setIsEditModalOpen(true);
    };

    useEffect(() => {
        axios.get(`${URL}question/all`,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setData(res.data.questions);
            })
            .catch((error) => console.error(error));
    }, [render]);

    const handleMenuClick = (event, row) => {
        setMenuAnchorEl(event.currentTarget);
        setCurrentRow(row);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setCurrentRow(null);
    };

    const handleEdit = () => {
        handleMenuClose();
        handleOpenEditModal(currentRow);
    };

    const onAssignToQuestion = (data) => {
        setAssignMultipleToCategoty(data);
        setAssignCategoryModalBulkOpen(true);
        handleMenuClose();
    };

    const handleAssignCategory = (question) => {
        setSelectedQuestionForCategory(question);
        setAssignCategoryModalOpen(true);
        handleMenuClose();
    };

    const columns = [
        { field: "_id", headerName: "ID", flex: 1, resizeable: true },
        { field: "questionText", headerName: "Question Text", flex: 2, resizeable: true },
        { field: "searchKeyword", headerName: "Search Keyword", flex: 2, resizeable: true },
        { field: "location", headerName: "Location", flex: 1, resizeable: true },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <IconButton
                    aria-label="more"
                    onClick={(event) => handleMenuClick(event, params.row)}
                >
                    <CgMoreVertical />
                </IconButton>
            ),
        }
    ];

    const flattenQuestions = data.map((row) => ({
        id: row._id,
        _id: row._id,
        questionText: row.questionText,
        searchKeyword: row.searchKeyword,
        location: row.location,
    }));

    console.log("DATA  : ", data)
    return (
        <div style={{ width: "100%" }}>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <EditQuestionModal
                open={isEditModalOpen}
                handleClose={() => setIsEditModalOpen(false)}
                questionData={currentQuestionData}
                setRender={setRender}
                render={render}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5">Questions</Typography>
                <CreateQuestion render={render} passSelectedCategoryId={passSelectedCategoryId} setRender={setRender} />
            </div>
            {data.length > 0 ? (
                <>
                    <QuestionCategoriesMain render={render} setRender={setRender} setPassSelectedCategoryId={setPassSelectedCategoryId} data={data} rows={flattenQuestions} columns={columns} onAssignToQuestion={onAssignToQuestion} />

                    <ImportQuestion render={render} setRender={setRender} questionCategoryId={passSelectedCategoryId} />
                    <Menu
                        anchorEl={menuAnchorEl}
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleEdit}>
                            <ListItemIcon>
                                <CiEdit fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Edit</ListItemText>
                        </MenuItem>
                        {/* {!currentRow?.isAssignCategory && (
                            <MenuItem onClick={() => handleAssignCategory(currentRow)}>
                                <ListItemIcon>
                                    <BiCategoryAlt fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Assign/Change Category</ListItemText>
                            </MenuItem>
                        )} */}
                    </Menu>
                </>
            ) : (
                <Circular />
            )}
            {/* {assignCategoryModalOpen && (
                <ChangeQuestionCategoryModal
                    open={assignCategoryModalOpen}
                    onClose={() => setAssignCategoryModalOpen(false)}
                    question={selectedQuestionForCategory}
                    setRender={setRender}
                    render={render}
                />
            )} */}
            {/* {assignCategoryModalBulkOpen && (
                <questionAssignCategoryModalBulk
                    open={assignCategoryModalBulkOpen}
                    onClose={() => setAssignCategoryModalBulkOpen(false)}
                    multiplequestions={assignMultipleToCategoty}
                    setRender={setRender}
                />
            )} */}
        </div>
    );
};

export default AllQuestion;

