// AttachProxyModal.js
import React, { useEffect, useState } from 'react';
import { Modal, Box, Button, Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';


const AttachProxyModal = ({ open, onClose, onAttach, selectedRow }) => {
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const [data, setData] = useState([]);
  const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}proxy/matchproxies/${selectedRow.region}`)
      .then((res) => {
        // console.log("DATA ::: ", res.data)
        setData(res.data);
      })
      .catch((error) => console.error(error));
  }, [selectedRow.region]);

  const handleAttach = async () => {
    if (selectedAccountId) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}proxy/attachproxy`,{        
          accountId: selectedRow._id,
          proxyCategoryId: selectedAccountId,
        }, {
          headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
          }});
        if (response.status === 200) {
          onAttach(selectedAccountId);
          onClose();
        }
        if (ToasterToggle) {
          toast.success(response.data.message || "Proxy attached to account successfully")
        }
      } catch (error) {
        if (ToasterToggle) {
          toast.error(error.response.data.message || "Proxy attached to account Failed!")
        }
        console.error('Error attaching Proxy to Account:', error.message);
      }
    }
  };
  // console.log("DATA CALLLED")
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2
      }}>
        <Typography variant="h6" component="h2" sx={{ mb: 2, color: 'black' }}>
          Attach Proxy Category
        </Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="account-dropdown" sx={{ color: 'black' }}>Select Category</InputLabel>
          <Select
            labelId="account-dropdown"
            value={selectedAccountId}
            label="Select Account"
            onChange={(e) => setSelectedAccountId(e.target.value)}
            sx={{
              '& .MuiSvgIcon-root': { color: 'black' },
              '& .MuiOutlinedInput-notchedOutline': { borderColor: 'black' },
              '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'black' },
              '& .MuiSelect-select': { color: 'black' },
            }}
            MenuProps={{
              sx: {
                '& .MuiPaper-root': {
                  bgcolor: 'white',
                  '& .MuiMenuItem-root': {
                    color: 'black',
                    '&:hover': {
                      bgcolor: '#f5f5f5',
                    },
                  },
                },
              },
            }}
          >
            {data?.map((val) => (
              <MenuItem key={val._id} value={val._id}>{val.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handleAttach} sx={{ backgroundColor: "#1f6291", color: 'white', '&:hover': { backgroundColor: "#10104e" } }}>
          Attach
        </Button>
      </Box>
    </Modal>
  );
};

export default AttachProxyModal;
