import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography, TextField, Checkbox, FormControlLabel, Select, MenuItem, InputLabel, Slider, Tabs, Tab } from '@mui/material';
import Modal from '@mui/material/Modal';
import { AiFillCloseCircle } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios"


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const AccountModal = ({ ButtonName, render, setRender, passSelectedCategoryId }) => {
    const [open, setOpen] = useState(false);
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))
    const [proxyCategories, setProxyCategories] = useState([]);

    const [formData, setFormData] = useState({
        profileType: 'Single',
        range: 1,
        profileName: '',
        region: '',
        os: '',
        browser: '',
        randomize: false,
        proxyCategoryId: '',
    });


    useEffect(() => {
        fetchProxyCategories();
    }, []);

    const fetchProxyCategories = async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}proxy/matchproxies/default`,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        setProxyCategories(response.data);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleProfileTypeChange = (event, newValue) => {
        setFormData({
            ...formData,
            profileType: newValue,
        });
    };

    const handleSliderChange = (event, value) => {
        setFormData({
            ...formData,
            range: value,
        });
    };

    const callToast = (Sub) => {
        if (ToasterToggle) { // Check if toaster should be displayed

            toast.error(Sub, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    const handleSave = () => {
        // console.log(formData)
        if (formData.profileName === '' || formData.region === '') {
            callToast("Profile Name and Region are required fields")
            return;
        }
        if (!formData.randomize) {
            if (formData.os === '' || formData.browser === '') {
                callToast('OS and Browser are required for Bulk Profile');
                return;
            }
        }

        if (formData.proxyCategoryId === '') {
            callToast("Please Select a Category ID")
            return;
        }

        if (formData.profileType === 'Bulk') {
            if (formData.range === 0) {
                console.error('Range is required for Bulk Profile');
                return;
            }
        };
        // console.log('Form Data:', formData);
        // You can send formData in your API call here
        // axios.post()
        const postData = {
            ...formData,
            categoryId: passSelectedCategoryId,
        };

        axios.post(`${process.env.REACT_APP_BASE_URL}account/createaccount`,
            postData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                if (ToasterToggle) { // Check if toaster should be displayed

                    // console.log(res)
                    toast.success("Account Created Successfully", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setRender(!render)
                }
            })
            .catch((error) => {
                if (ToasterToggle) { // Check if toaster should be displayed

                    // console.log(res)
                    toast.error(error?.response?.data?.message || "Account Creation Failed", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setRender(!render)
                }
                console.log(error)
            })
        setFormData(() => {
            return {
                profileType: 'Single',
                range: 1,
                profileName: '',
                region: '',
                os: '',
                browser: '',
                randomize: false,
                proxyCategoryId: '',
            }
        })
        handleClose()
    };

    const handleRandomizeChange = (event) => {
        const isChecked = event.target.checked;
        setFormData({
            ...formData,
            randomize: isChecked,
            os: isChecked ? '' : formData.os,
            browser: isChecked ? '' : formData.browser,
        });
    };

    // console.log("DATA : ", formData)
    return (
        <div>
            <Button variant="contained" style={{ backgroundColor: 'Black' }} onClick={handleOpen}>
                {ButtonName}
            </Button>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <AiFillCloseCircle
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            cursor: 'pointer',
                            fontSize: '24px',
                            color: "black"
                        }}
                        onClick={handleClose}
                    />

                    <Typography variant="h6" component="h2" sx={{ color: 'black' }} >
                        {ButtonName} Profile
                    </Typography>
                    <Tabs
                        value={formData.profileType}
                        onChange={handleProfileTypeChange}
                        aria-label="profile type tabs"
                        variant="fullWidth"
                    >
                        <Tab label="Single Profile" value="Single" />
                        <Tab label="Bulk Profile" value="Bulk" />
                    </Tabs>
                    {formData.profileType === 'Bulk' && (
                        <Slider
                            value={formData.range}
                            onChange={handleSliderChange}
                            min={1}
                            max={1000}
                            valueLabelDisplay="auto"
                        />
                    )}
                    <TextField
                        label="Profile Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.profileName}
                        onChange={(e) => setFormData({ ...formData, profileName: e.target.value })}
                    />
                    <TextField
                        label="Region"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.region}
                        onChange={(e) => setFormData({ ...formData, region: e.target.value })}
                    />
                    <InputLabel>OS (Mac, Windows, Linux, Android)</InputLabel>
                    <Select
                        label="OS"
                        variant="outlined"
                        fullWidth
                        value={formData.os}
                        onChange={(e) => setFormData({ ...formData, os: e.target.value })}
                        disabled={formData.randomize}
                    >
                        <MenuItem value="Mac">Mac</MenuItem>
                        <MenuItem value="Windows">Windows</MenuItem>
                        <MenuItem value="Linux">Linux</MenuItem>
                        <MenuItem value="Android">Android</MenuItem>
                    </Select>
                    <InputLabel>Browser (Mimic, Stealthfox)</InputLabel>
                    <Select
                        label="Browser"
                        variant="outlined"
                        fullWidth
                        value={formData.browser}
                        onChange={(e) => setFormData({ ...formData, browser: e.target.value })}
                        disabled={formData.randomize}
                    >
                        <MenuItem value="Mimic">Mimic</MenuItem>
                        <MenuItem value="Stealthfox">Stealthfox</MenuItem>
                    </Select>

                    <InputLabel sx={{ marginTop: "10px" }}>Proxy Category</InputLabel>
                    <Select labelId="proxy-category-label" label="Proxy Category" variant="outlined" fullWidth value={formData.proxyCategoryId} onChange={(e) => setFormData({ ...formData, proxyCategoryId: e.target.value })} displayEmpty>
                        {/* <MenuItem ><em>Proxy Category</em></MenuItem> */}
                        {proxyCategories.map((category) => (
                            <MenuItem key={category._id} value={category._id}>{category.name}</MenuItem>
                        ))}
                    </Select>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.randomize}
                                onChange={handleRandomizeChange}
                            />
                        }
                        label="Randomize"
                        sx={{ color: 'black' }}

                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="outlined" color="success" onClick={handleSave}>
                            Save
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default AccountModal;


