import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios"


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const GmailModal = ({ ButtonName, render, setrender }) => {
    const [open, setOpen] = useState(false);
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))
    const [formData, setFormData] = useState({
        gmail: '',
        phn_no: '',
        password: '',
        recovery_email: '',
        secret_key: ''
    });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        // console.log('Form Data:', formData);
        if (
            formData.gmail === '' ||
            formData.phn_no === '' ||
            formData.recovery_email === '' ||
            formData.password === '' ||
            formData.secret_key === ''
        ) {
            console.error('All fields must be filled out');
            if (ToasterToggle) {

                toast.error('All fields must be filled out', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }

        } else {
            // console.log('Form Data:', formData);
            axios.post(`${process.env.REACT_APP_BASE_URL}gmail/creategmail`, formData ,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then((res) => {
                    // console.log(res)
                    if (ToasterToggle) {

                        toast.success('Gmail Created Successfully', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                    setrender(!render)
                })
                .catch((error) => {
                    if (ToasterToggle) {

                        toast.error(error.response.data.message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                    console.log(error)
                })
            setFormData(() => {
                return {
                    gmail: '',
                    phn_no: '',
                    recovery_email: '',
                    password: '',
                    secret_key: '',
                }
            })
            handleClose()
        }
    };

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <Button variant="contained" style={{ backgroundColor: 'Black' }} onClick={handleOpen}>
                {ButtonName}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Typography variant="h6" component="h2" style={{ color: 'Black' }} mt={2}>
                        {ButtonName}
                    </Typography>
                    <TextField
                        label="Gmail"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.gmail}
                        onChange={(e) => setFormData({ ...formData, gmail: e.target.value })}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.password}
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    />
                    <TextField
                        label="Phone Number"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.phn_no}
                        onChange={(e) => setFormData({ ...formData, phn_no: e.target.value })}
                    />
                    <TextField
                        label="Recovery Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.recovery_email}
                        onChange={(e) => setFormData({ ...formData, recovery_email: e.target.value })}
                    />
                    <TextField
                        label="Phone Id"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.secret_key}
                        onChange={(e) => setFormData({ ...formData, secret_key: e.target.value })}
                    />

                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="outlined" color="success" onClick={handleSave}>
                            Save
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default GmailModal;
