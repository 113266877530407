import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, TextField, Modal } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const EditQuestionModal = ({ open, handleClose, questionData, setRender, render }) => {
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"));
    const [formData, setFormData] = useState({
        questionText: '',
        location: '',
    });

    useEffect(() => {
        if (questionData) {
            setFormData({ ...questionData });
        }
    }, [questionData]);

    const handleSave = () => {
        if (!formData.questionText) {
            if (ToasterToggle) {
                toast.error('All fields must be filled out');
            }
            return;
        }

        axios.put(`${process.env.REACT_APP_BASE_URL}question/${questionData._id}`, formData ,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(() => {
                if (ToasterToggle) {
                    toast.success('Question updated successfully');
                }
                setRender(!render);
                handleClose();
            })
            .catch((error) => {
                if (ToasterToggle) {
                    toast.error('Error updating question');
                }
                console.error('Error updating question:', error);
            });
    };

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="edit-question-modal-title"
                aria-describedby="edit-question-modal-description"
            >
                <Box sx={style}>
                    <Typography id="edit-question-modal-title" variant="h6" component="h2" style={{ color: "black" }}>
                        Edit Question
                    </Typography>
                    <TextField label="question Text" variant="outlined" fullWidth margin="normal" value={formData.questionText} onChange={(e) => setFormData({ ...formData, questionText: e.target.value })} />
                    <TextField label="Location" variant="outlined" fullWidth margin="normal" value={formData.location} onChange={(e) => setFormData({ ...formData, location: e.target.value })} />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleClose} sx={{ mr: 1 }}>Cancel</Button>
                        <Button onClick={handleSave} variant="contained">Save</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default EditQuestionModal;

