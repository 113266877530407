import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import axios from "axios";
import Circular from '../components/Circular';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import SpecificReviewLogsCategoriesMain from '../components/specific-review-logs/SpecificReviewLogsCategoriesMain';
import StarIcon from '@mui/icons-material/Star';


const AllSpecificReviewLogs = () => {
    const [data, setData] = useState([]);
    const [render, setRender] = useState(false);
    const navigate = useNavigate();
    const [passSelectedCategoryId, setPassSelectedCategoryId] = useState('all');


    useEffect(() => {
        const token = localStorage.getItem('token');    
        if (!token) {
        console.log("No token found, redirecting to login");
        navigate("/login");
        }
    }, [])


    useEffect(() => {
        fetchReviewLogs();
    }, [render]);

    const fetchReviewLogs = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}specificReviewLog/review-logs`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            setData(response.data.specificReviewLogs);
        } catch (error) {
            toast.error('Failed to fetch review logs');
            console.error('Error fetching review logs:', error);
        }
    };

    const columns = [
        { field: 'reviewText', headerName: 'Review Text', flex: 3 },
        { field: 'profileName', headerName: 'Account Name', flex: 2 },
        { field: 'gmail', headerName: 'Gmail', flex: 2 },
        {
            field: 'rating',
            headerName: 'Rating',
            flex: 2,
            renderCell: (params) => (
                <Box display="flex" alignItems="center">
                    {Array.from({ length: 5 }, (_, index) => (
                        <StarIcon key={index} style={{ color: index < params.value ? '#ffb400' : '#e4e5e9' }} />
                    ))}
                </Box>
            ),
        },
        {
            field: 'reviewLink',
            headerName: 'Review Link',
            flex: 3,
            renderCell: (params) => (
                <a href={params.value} target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'underline' }}>
                    {params.value}
                </a>
            ),
        },    
        {
            field: 'isPublic',
            headerName: 'Public',
            flex: 1,
        },
        { field: 'runTime', headerName: 'Run Time', flex: 2,  
            valueGetter: ({ value }) => {
            const date = new Date(value);
            return `${String(date.getUTCFullYear())}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')} ` + 
                   `${String(date.getUTCHours()).padStart(2, '0')}:${String(date.getUTCMinutes()).padStart(2, '0')}:${String(date.getUTCSeconds()).padStart(2, '0')}`;
        },
    }
    ];

    const rows = data.map((log) => ({
        id: log._id,
        reviewText: log.reviewText,
        rating: log.rating,
        reviewLink: log.reviewLink,
        runTime: log.runTime,
        isPublic: log.isPublic,
        profileName: log.account_id.profileName,
        gmail: log.account_id?.gmail?.gmail || "",
    }));
    return (
        <div style={{ width: "100%" }}>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5">Specific Review Logs</Typography>
            </div>
            {data.length > 0 ? (
                <>
                    <SpecificReviewLogsCategoriesMain render={render} setRender={setRender} setPassSelectedCategoryId={setPassSelectedCategoryId} data={data} rows={rows} columns={columns}/>
                </>
            ) : (
                <Circular />
            )}
        </div>
    );
};

export default AllSpecificReviewLogs;

