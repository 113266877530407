import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const CheckToken = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const token = localStorage.getItem('token');    
        if (!token) {
        console.log("No token found, redirecting to login");
        navigate("/login");
        }
    }, []);
        return (
        <div>
        </div>
    )
}

export default CheckToken
