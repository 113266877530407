import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, TextField, Modal } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const EditProxyModal = ({ open, handleClose, proxyData, setrender, render }) => {
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))
    const [formData, setFormData] = useState({
        ip: '',
        port: '',
        user: '',
        password: '',
        location: '',
        protocol: '',
    });

    // Load the selected proxy's data into the form when the modal opens
    useEffect(() => {
        if (proxyData) {
            setFormData({ ...proxyData });
        }
    }, [proxyData]);

    const handleSave = () => {
        // Validate form data
        if (!formData.ip || !formData.port || !formData.user || !formData.password || !formData.location || !formData.protocol) {
            if (ToasterToggle) {

                toast.error('All fields must be filled out');
            }
            return;
        }

        // Submit updated proxy details
        axios.put(`${process.env.REACT_APP_BASE_URL}proxy/editproxy/${proxyData._id}`, formData ,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                if (ToasterToggle) {

                    toast.success('Proxy updated successfully');
                }
                setrender(!render); // Trigger a re-render or data refresh
                handleClose(); // Close modal after saving
            })
            .catch((error) => {
                if (ToasterToggle) {

                    toast.error('Error updating proxy');
                }
                console.error('Error updating proxy:', error);
            });
    };

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="edit-proxy-modal-title"
                aria-describedby="edit-proxy-modal-description"
            >
                <Box sx={style}>


                    <Typography id="edit-proxy-modal-title" variant="h6" component="h2" style={{ color: "black" }}>
                        Edit Proxy
                    </Typography>
                    {/* Form fields */}
                    <TextField label="IP" variant="outlined" fullWidth margin="normal" value={formData.ip} onChange={(e) => setFormData({ ...formData, ip: e.target.value })} />
                    <TextField label="Port" variant="outlined" fullWidth margin="normal" value={formData.port} onChange={(e) => setFormData({ ...formData, port: e.target.value })} />
                    <TextField label="User" variant="outlined" fullWidth margin="normal" value={formData.user} onChange={(e) => setFormData({ ...formData, user: e.target.value })} />
                    <TextField label="Password" variant="outlined" fullWidth margin="normal" value={formData.password} onChange={(e) => setFormData({ ...formData, password: e.target.value })} />
                    <TextField label="Location" variant="outlined" fullWidth margin="normal" value={formData.location} onChange={(e) => setFormData({ ...formData, location: e.target.value })} />
                    <TextField label="Protocol" variant="outlined" fullWidth margin="normal" value={formData.protocol} onChange={(e) => setFormData({ ...formData, protocol: e.target.value })} />
                    {/* Action buttons */}
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleClose} sx={{ mr: 1 }}>Cancel</Button>
                        <Button onClick={handleSave} variant="contained">Save</Button>
                    </Box>
                </Box>
            </Modal>
        </>);
};

export default EditProxyModal;
