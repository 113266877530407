import React, { useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from 'react-toastify';

const ImportLinks = () => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setError('');
    };

    const handleFileUpload = async () => {
        try {
            if (!file) {
                setError('Please select a file.');
                return;
            }

            const formData = new FormData();
            formData.append('file', file);

            const URL = process.env.REACT_APP_BASE_URL;
            const res = await axios.post(`${URL}link/upload`, formData,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            // console.log("RESPONSE", res)
            if (ToasterToggle) { // Check if toaster should be displayed

                toast.success(res.data.message || "Success!")
            }
            // Reset the file input
            setFile(null);
            // Clear the file input value
            document.getElementById('fileInput').value = null;

            // Trigger re-render or update data as needed
            // setrender(!render);
        } catch (error) {
            console.error('Error uploading file:', error);
            if (ToasterToggle) { // Check if toaster should be displayed

                toast.error("Error uploading file!")
            }
            setError('Error uploading file. Please try again.');
        }
    };

    return (
        <div style={{ marginBottom: '20px', padding: '10px', border: '1px solid #10104e', borderRadius: '5px' }}>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <label style={{ marginRight: '10px' }}>Choose File:</label>
            <input type="file" id="fileInput" onChange={handleFileChange} />
            <Button variant="contained" style={{ backgroundColor: 'Black', marginLeft: '10px' }} onClick={handleFileUpload}>
                Upload File
            </Button>

            {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
        </div>
    );
};

export default ImportLinks;
