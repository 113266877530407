import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const SpecificReviewLogsCategoriesTable = ({ categories, onCategorySelect, handleDeleteCategory }) => {
    const allCategories = [...categories];

    const columns = [
        {
            field: 'linkName',
            headerName: 'GMB List',
            flex: 3,
        },
    ];

    const rows = allCategories.map((category) => ({
        id: category._id,
        linkName: category.linkName || category.linkName,
    }));
    const handleRowClick = (params) => {
        onCategorySelect(params.id);
    };

    return (
        <div
            style={{
                height: '370px',
                width: '100%',
                overflow: 'hidden',
                border: 'none',
                resize: 'vertical',
                minHeight: '200px',
                marginTop: '20px',
            }}
        >
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                pagination
                onRowClick={handleRowClick}
                hideFooter
                sx={{
                    fontFamily: 'Poppins, sans-serif',
                    '& .MuiDataGrid-columnHeaders': {
                        color: 'white',
                        fontSize: '1.25rem',
                        borderBottom: 'solid',
                    },
                    '& .MuiDataGrid-row': {
                        backgroundColor: 'linear-gradient(to right, #000000, #06041f, #0d0d55, #15178e)',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#2D3748',
                        },
                        '&.Mui-selected': {
                            backgroundColor: '#4A5568 !important',
                            '&:hover': {
                                backgroundColor: '#4A5568',
                            },
                        },
                    },
                    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-select, & .MuiTablePagination-displayedRows': {
                        color: 'white',
                    },
                    '& .MuiDataGrid-root': {
                        boxShadow: 'none',
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                }}
            />
        </div>
    );
};

export default SpecificReviewLogsCategoriesTable;

