import React, { useEffect, useState } from 'react';
// import DataTable from '../components/DataTable';
import { CircularProgress, Typography, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip, Button } from '@mui/material';
import axios from "axios";
import Circular from '../components/Circular';
// import ImportReview from '../components/ImportReview';
import { useNavigate } from 'react-router-dom';
import { MdMoreVert } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { AiTwotoneDelete } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';

import { BiCategoryAlt } from "react-icons/bi";
import { CgMoreVertical } from "react-icons/cg";
import EditSpecificReviewModal from '../components/specific-review/EditSpecificReviewModal';
import CreateSpecificReview from '../components/specific-review/CreateSpecificReview';
import SpecificReviewCategoriesMain from '../components/specific-review/SpecificReviewCategoriesMain';
import ImportSpecificReview from '../components/specific-review/ImportSpecificReview';
import ChangeSpecificReviewCategoryModal from '../components/specific-review/ChangeSpecificReviewCategoryModal';
import AssignPBSLink from '../components/specific-review/AssignPBSLink';
// import ReviewAssignCategoryModalBulk from '../components/ReviewAssignCategoryModalBulk';

const AllSpecificReviews = () => {
    const URL = process.env.REACT_APP_BASE_URL;
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"));
    const [data, setData] = useState([]);
    const [render, setRender] = useState(false);
    const navigate = useNavigate();
    const [passSelectedCategoryId, setPassSelectedCategoryId] = useState('all');
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentReviewData, setCurrentReviewData] = useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [assignMultipleToCategory, setAssignMultipleToCategoty] = useState([]);
    const [assignCategoryModalOpen, setAssignCategoryModalOpen] = useState(false);
    const [assignPBSModalOpen, setAssignPBSModalOpen] = useState(false);

    const [assignCategoryModalBulkOpen, setAssignCategoryModalBulkOpen] = useState(false);

    const [selectedReviewForCategory, setSelectedReviewForCategory] = useState(null);


    useEffect(() => {
        const token = localStorage.getItem('token');    
        if (!token) {
        console.log("No token found, redirecting to login");
        navigate("/login");
        }
    }, [])

    const handleOpenEditModal = (reviewData) => {
        setCurrentReviewData(reviewData);
        setIsEditModalOpen(true);
    };

    useEffect(() => {
        axios.get(`${URL}specific-review/all`,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setData(res.data.specificReviews);
            })
            .catch((error) => console.error(error));
    }, [render]);

    const handleDeleteReview = (reviewId) => {
        axios.delete(`${URL}specific-review/deletereview/${reviewId}`,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(() => {
                if (ToasterToggle) {
                    toast.success('Review deleted successfully');
                }
                setRender((prev) => !prev);
            })
            .catch((error) => {
                if (ToasterToggle) {
                    toast.error('Error deleting review');
                }
            });
    };

    const handleMenuClick = (event, row) => {
        setMenuAnchorEl(event.currentTarget);
        setCurrentRow(row);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setCurrentRow(null);
    };

    const handleEdit = () => {
        handleMenuClose();
        handleOpenEditModal(currentRow);
    };

    const onAssignToReview = (data) => {
        setAssignMultipleToCategoty(data);
        setAssignCategoryModalBulkOpen(true);
        handleMenuClose();
    };

    const handleAssignCategory = (review) => {
        setSelectedReviewForCategory(review);
        setAssignCategoryModalOpen(true);
        handleMenuClose();
    };
    
    const handleAssignPBSLink = (review) => {
        setSelectedReviewForCategory(review);
        setAssignPBSModalOpen(true)
    };

    const columns = [
        { field: "_id", headerName: "ID", flex: 1, resizeable: true },
        { field: "reviewText", headerName: "Review Text", flex: 2, resizeable: true },
        { field: "searchKeyword", headerName: "Search Keyword", flex: 2, resizeable: true },
        { field: "location", headerName: "Location", flex: 1, resizeable: true },
        { field: "rating", headerName: "Rating", flex: 1, resizeable: true },
        {
            field: 'pbsLink',
            headerName: 'GMB Link',
            flex: 2,
            renderCell: (params) => (
              <div>
                {params.row.pbsLink ? (
                  <Tooltip title={params.row.pbsLink?.linkName}>
                    {params.row.pbsLink?.linkName}
                  </Tooltip>
                ) : (
                  <Button
                    style={{ backgroundColor: '#1B3C73', color: "white" }}
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      handleAssignPBSLink(params.row)
                    }}
                  >
                    Attach
                  </Button>
                )}
              </div>
            ),
          },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <IconButton
                    aria-label="more"
                    onClick={(event) => handleMenuClick(event, params.row)}
                >
                    <CgMoreVertical />
                </IconButton>
            ),
        }
    ];

    const flattenReviews = data.map((row) => ({
        id: row._id,
        _id: row._id,
        reviewText: row.reviewText,
        searchKeyword: row.searchKeyword,
        location: row.location,
        rating: row.rating,
        pbsLink: row.pbsLink,

    }));

    console.log("selectedReviewForCategory : " , selectedReviewForCategory);
    return (
        <div style={{ width: "100%" }}>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <EditSpecificReviewModal
                open={isEditModalOpen}
                handleClose={() => setIsEditModalOpen(false)}
                reviewData={currentReviewData}
                setRender={setRender}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5">Specific Reviews</Typography>
                <CreateSpecificReview render={render} passSelectedCategoryId={passSelectedCategoryId} setRender={setRender} />
            </div>
            {data.length > 0 ? (
                <>
                    <SpecificReviewCategoriesMain render={render} setRender={setRender} setPassSelectedCategoryId={setPassSelectedCategoryId} data={data} rows={flattenReviews} columns={columns} onAssignToReview={onAssignToReview} />

                    <ImportSpecificReview render={render} setRender={setRender} reviewCategoryId={passSelectedCategoryId} />
                    <Menu
                        anchorEl={menuAnchorEl}
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleEdit}>
                            <ListItemIcon>
                                <CiEdit fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Edit</ListItemText>
                        </MenuItem>
                        {!currentRow?.isAssignCategory && (
                            <MenuItem onClick={() => handleAssignCategory(currentRow)}>
                                <ListItemIcon>
                                    <BiCategoryAlt fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Assign/Change Category</ListItemText>
                            </MenuItem>
                        )}
                     </Menu>
                </>
            ) : (
                <Circular />
            )}
            {assignCategoryModalOpen && (
                <ChangeSpecificReviewCategoryModal
                    open={assignCategoryModalOpen}
                    onClose={() => setAssignCategoryModalOpen(false)}
                    review={selectedReviewForCategory}
                    setRender={setRender}
                    render={render}
                />
            )}
            {assignPBSModalOpen && (
                    <AssignPBSLink
                        reviewId={selectedReviewForCategory?.id}
                        open={assignPBSModalOpen}
                        onClose={() => setAssignPBSModalOpen(false)}
                        setAssignPBSModalOpen
                        render={render}
                        setRender={setRender}
                    />
                )}
  
            {/* {assignCategoryModalBulkOpen && (
                <ReviewAssignCategoryModalBulk
                    open={assignCategoryModalBulkOpen}
                    onClose={() => setAssignCategoryModalBulkOpen(false)}
                    multipleReviews={assignMultipleToCategoty}
                    setRender={setRender}
                />
            )} */}
        </div>
    );
};

export default AllSpecificReviews;

