import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import CreateProxyCategoryModal from './CreateProxyCategoryModal';
import ProxyCategoriesTable from './ProxyCategoriesTable';
import DataTable from './DataTable';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import SearchProxy from './SearchProxy';

const ProxyCategoriesMain = ({ render, setrender, data, rows, setPassSelectedCategoryId, columns, onAssignToProxy }) => {
    // console.log("DATA : ", data)
    const [rowsData, setRowsData] = useState([])
    const [categories, setCategories] = useState([]);
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))
    const [selectedCategoryId, setSelectedCategoryId] = useState('all');
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] = useState(false);
    const [delCategoryId, setDelCategoryId] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});


    const [delData, setDeleteData] = useState([])

    useEffect(() => {
        setRowsData(rows)
        fetchCategories();
        loadColumnVisibility();
    }, [render]);

    useEffect(() => {
        handleCategorySelect(selectedCategoryId);
    }, [render]);


    const handleBulkDelete = async (deleteData) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}proxy/bulkdelete`, { ids: deleteData },{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (ToasterToggle) { // Check if toaster should be displayed

                toast.success(response.data.message || 'Selected Proxy records deleted successfully');
            }
            setrender(prev => !prev); // Refresh the Proxy list
        } catch (error) {
            if (ToasterToggle) { // Check if toaster should be displayed

                toast.error(error.response.data.message || 'Failed to delete selected Proxy records');
            }
            console.error(error);
        }
    };






    const handleDeleteCategory = async (categoryId) => {
        try {
            // console.log("category ID ", categoryId)
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}proxycategory/delete/${categoryId}`,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (ToasterToggle) { // Check if toaster should be displayed

                toast.success(response.data.message || 'Category deleted successfully');
            }
            setrender(prev => !prev); // Refresh the categories list
        } catch (error) {
            if (ToasterToggle) { // Check if toaster should be displayed

                toast.error(error.response.data.message || 'Failed to delete category');
            }
            console.error('Error deleting category:', error);
        }
    };


    const ConfirmHandleDelete = async (deleteData) => {
        setIsDeleteModalOpen(true);
        setDeleteData(deleteData)
    }

    const handleDeleteConfirm = () => {
        setIsDeleteModalOpen(false);
        handleBulkDelete(delData)
    };

    const handleDeleteCancel = () => {
        setIsDeleteModalOpen(false);
    };

    const ConfirmHandleDeleteCategory = async (deleteData) => {
        setIsDeleteCategoryModalOpen(true);
        setDelCategoryId(deleteData)
    }

    const handleDeleteConfirmCategory = () => {
        setIsDeleteCategoryModalOpen(false);
        handleDeleteCategory(delCategoryId)
    };

    const handleDeleteCancelCategory = () => {
        setIsDeleteCategoryModalOpen(false);
    };

    const fetchCategories = async () => {
        try {

            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}proxyCategory/all`,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setCategories(response.data);
        } catch (error) {
            if (ToasterToggle) { // Check if toaster should be displayed

                toast.error('Failed to fetch categories');
            }
            console.error('Error fetching categories:', error);
        }
    };
    const fetchProxiesByCategory = async (categoryId, page = 1, limit = 100) => {
        try {
            let url = `${process.env.REACT_APP_BASE_URL}proxy/byCategory/${categoryId}?page=${page}&limit=${limit}`;

            const response = await axios.get(url,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setTotalPages(response.data.totalPages);
            setCurrentPage(response.data.currentPage);
            setTotalItems(response.data.totalItems);

            const flattenConnectedUsers = response.data.proxies.map((rowM, index) => {
                return {
                    id: rowM._id,
                    _id: rowM._id,
                    user: rowM.user,
                    ip: rowM.ip,
                    port: rowM.port,
                    password: rowM.password,
                    location: rowM.location,
                    protocol: rowM.protocol,
                    isAssignCategory: rowM.isAssignCategory
                };
            });

            setRowsData(flattenConnectedUsers); // Adjust based on the actual response structure
        } catch (error) {
            if (ToasterToggle) { // Check if toaster should be displayed

                toast.error('Failed to fetch proxies for the category');
            }

            console.error('Error fetching proxies by category:', error);
        }
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage); // Update the current page
        fetchProxiesByCategory(selectedCategoryId, newPage); // Fetch new data when the page changes
    };

    const handleCategorySelect = (categoryId) => {
        setCurrentPage(1)
        setSelectedCategoryId(categoryId);
        setPassSelectedCategoryId(categoryId)
        fetchProxiesByCategory(categoryId, 1);
    };

    const setSearchProxy = (data) => {
        setTotalPages(data.totalPages);  // Set the total pages from the API
        setCurrentPage(data.currentPage);  // Set the current page from the API
        setTotalItems(data.totalItems); // Set the total number of items

        const flattenConnectedUsers = data.proxies.map((rowM, index) => {
            return {
                id: rowM._id,
                _id: rowM._id,
                user: rowM.user,
                ip: rowM.ip,
                port: rowM.port,
                password: rowM.password,
                location: rowM.location,
                protocol: rowM.protocol,
                isAssignCategory: rowM.isAssignCategory
            };
        });

        setRowsData(flattenConnectedUsers);
    }
    const loadColumnVisibility = () => {
        const storedVisibility = localStorage.getItem('proxyColumnVisibility');
        if (storedVisibility) {
            setColumnVisibilityModel(JSON.parse(storedVisibility));
        }
    };

    const saveColumnVisibility = (model) => {
        localStorage.setItem('proxyColumnVisibility', JSON.stringify(model));
        setColumnVisibilityModel(model);
    };

    // console.log("ROWS AND COLUMNS : ", rows, columns)

    // console.log("CATEGORIES : ", categories)

    return (
        <div>
            <SearchProxy setSearchProxy={setSearchProxy} />

            <div style={{ display: "flex", flexDirection: "row", width: "100%" }} >
                <div style={{ flex: 2, marginRight: "20px" }}>
                    <ProxyCategoriesTable onCategorySelect={handleCategorySelect} categories={categories} handleDeleteCategory={ConfirmHandleDeleteCategory} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            style={{ width: "130px", height: "50px", padding: "10px", marginTop: "15px", backgroundColor: '#1B3C73', color: "white" }}
                            variant="outlined"
                            size="small"
                            onClick={() => setIsCreateModalOpen(true)}
                        >
                            Category
                        </Button>
                    </div>
                </div>
                <div style={{ flex: 10 }}>
                    {data.length > 0 && rowsData.length > 0 ? (
                        <>
                            <DataTable rows={rowsData} columns={columns} onDeleteSelected={ConfirmHandleDelete} onAssignToProxy={onAssignToProxy} showAssignToCategory={true} handlePageChange={handlePageChange} currentPage={currentPage} totalPages={totalPages} totalItems={totalItems} selectedCategoryId={selectedCategoryId} saveColumnVisibility={saveColumnVisibility} columnVisibilityModel={columnVisibilityModel} />
                        </>) : <> </>}
                </div>
            </div>
            <CreateProxyCategoryModal
                open={isCreateModalOpen}
                onClose={() => setIsCreateModalOpen(false)}
                fetchCategories={fetchCategories}
            />
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <ConfirmDeleteModal
                isOpen={isDeleteModalOpen}
                onClose={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
                message="Are you sure you want to delete this record?"
            />

            <ConfirmDeleteModal
                isOpen={isDeleteCategoryModalOpen}
                onClose={handleDeleteCancelCategory}
                onConfirm={handleDeleteConfirmCategory}
                message="Are you sure you want to delete this Category?"
            />
        </div>
    );
};

export default ProxyCategoriesMain;

