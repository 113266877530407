import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const ChangeGmailModal = ({ open, onClose, account, setRender }) => {
    const [gmail, setGmail] = useState([]);
    const [selectedGmailId, setselectedGmailId] = useState('');
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}gmail/allwithoutref`)
            .then((res) => {
                setGmail(res.data.gmails);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [open]);


    const handleChangeGmail = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}gmail/changeProxy`,{            
            accountId: account._id,
            newGmailId: selectedGmailId,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }})
            .then(response => {
                if (ToasterToggle) {
                    toast.success(response.data.message || 'Proxy changed successfully');
                }
                setRender(prev => !prev);
                onClose();
            })
            .catch(error => {
                if (ToasterToggle) {
                    toast.error(error.response.data.message || 'Failed to change proxy');
                }
                console.error(error);
            });
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2
            }}>
                <Typography variant="h6" sx={{ color: "black", marginBottom: "1rem" }}>Change Gmail</Typography>
                <FormControl fullWidth>
                    <InputLabel id="gmail-select-label" >Select New Gmail</InputLabel>
                    <Select
                        labelId="gmail-select-label"
                        value={selectedGmailId}
                        onChange={(e) => setselectedGmailId(e.target.value)}
                    >
                        {gmail.map((gmail) => (
                            <MenuItem key={gmail._id} value={gmail._id}>{gmail.gmail}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant='contained' sx={{ mt: 3 }} onClick={handleChangeGmail}>Change Gmail</Button>
            </Box>
        </Modal>
    );
};

export default ChangeGmailModal;