import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios"


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ProxyModal = ({ ButtonName, render, setrender, passSelectedCategoryId }) => {
    const [open, setOpen] = useState(false);
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))
    const [formData, setFormData] = useState({
        ip: '',
        port: '',
        user: '',
        password: '',
        location: '',
        protocol: '',
    });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        // console.log('Form Data:', formData);

        if (
            formData.ip === '' ||
            formData.port === '' ||
            formData.user === '' ||
            formData.password === '' ||
            formData.location === '' ||
            formData.protocol === ''
        ) {
            console.error('All fields must be filled out');
            if (ToasterToggle) { // Check if toaster should be displayed

                toast.error('All fields must be filled out', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else {
            const postData = {
                ...formData,
                categoryId: passSelectedCategoryId,
            };
            // console.log('Form Data:', postData);

            axios.post(`${process.env.REACT_APP_BASE_URL}proxy/createproxy`, postData ,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then((res) => {
                    // console.log(res)
                    if (ToasterToggle) { // Check if toaster should be displayed

                        toast.success('Proxy Created Successfully', {
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                    setrender(!render)
                })
                .catch((error) => { console.log(error) })
            setFormData(() => {
                return {
                    ip: '',
                    port: '',
                    user: '',
                    password: '',
                    location: '',
                    protocol: '',
                }
            })
            handleClose()
        }
    };

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <Button variant="contained" style={{ backgroundColor: 'Black' }} onClick={handleOpen}>
                {ButtonName}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Typography variant="h6" style={{ color: "black" }} component="h2" mt={2}>
                        {ButtonName}
                    </Typography>
                    <TextField
                        label="IP"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.ip}
                        onChange={(e) => setFormData({ ...formData, ip: e.target.value })}
                    />
                    <TextField
                        label="Port"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.port}
                        onChange={(e) => setFormData({ ...formData, port: e.target.value })}
                    />
                    <TextField
                        label="User"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.user}
                        onChange={(e) => setFormData({ ...formData, user: e.target.value })}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.password}
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    />
                    <TextField
                        label="Location"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.location}
                        onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                    />
                    <TextField
                        label="Protocol"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.protocol}
                        onChange={(e) => setFormData({ ...formData, protocol: e.target.value })}
                    />


                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="outlined" color="success" onClick={handleSave}>
                            Save
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default ProxyModal;
