import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import { TextField, InputAdornment, CircularProgress, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchKeyword = ({ setSearchKeyword }) => {
    const [query, setQuery] = useState('');  // Search query (can be review text or location)
    const [loading, setLoading] = useState(false);
    const [noResults, setNoResults] = useState(false);

    const limit = 100;  // Number of items per page

    // Function to fetch reviews based on search query
    const fetchKeywords = async (searchQuery, page) => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}keyword/search`, {
                params: {
                    query: searchQuery,
                    page: page,
                    limit: limit
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            setSearchKeyword(response.data); // Set the fetched reviews in the parent component
            if (response.data.keywords.length === 0) {
                setNoResults(true);
            } else {
                setNoResults(false);
            }

            setLoading(false);
        } catch (error) {
            console.error('Error fetching keywords:', error);
            setLoading(false);
        }
    };

    // Debounced search function
    const debouncedSearch = useCallback(debounce((searchQuery) => {
        if (searchQuery) {  // Trigger only if there's a search query
            fetchKeywords(searchQuery, 1);  // Fetch reviews from page 1 on new search
        }
    }, 500), []);

    // Effect to trigger the search whenever query changes
    useEffect(() => {
        if (query) {
            debouncedSearch(query);
        }
    }, [query, debouncedSearch]);

    // Handle page change if needed
    const handlePageChange = (newPage) => {
        fetchKeywords(query, newPage);
    };

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', my: 0.3, gap: 2 }}>
            {/* Search Input */}
            <TextField
                variant="outlined"
                label="Search by Keyword"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                sx={{
                    width: '300px',
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#e0e0e0',
                        },
                        '&:hover fieldset': {
                            borderColor: '#bdbdbd',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#9e9e9e',
                        },
                    },
                    '& .MuiInputBase-input': {
                        color: 'white',
                    },
                    '& .MuiInputLabel-root': {
                        color: '#757575',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: '#616161',
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon sx={{ color: 'white' }} />
                        </InputAdornment>
                    )
                }}
            />

            {/* Loading Indicator */}
            {loading && (
                <CircularProgress />
            )}

            {/* No results message */}
            {!loading && noResults && <p>No results found</p>}
        </Box>
    );
};

export default SearchKeyword;

