import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const AssignSpecificReviewCategoryToPoolCategoryModal = ({ open, onClose, category }) => {
    const [reviewCategories, setReviewCategories] = useState([]);
    const [selectedReviewCategory, setSelectedReviewCategory] = useState('');

    useEffect(() => {
        fetchReviewCategories();
    }, []);

    const fetchReviewCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}pool-specific-review-category/all-categories`,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setReviewCategories(response.data);
        } catch (error) {
            toast.error('Failed to fetch review categories');
            console.error('Error fetching review categories:', error);
        }
    };

    const handleAssign = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}specific-review-category/assign-pool-specific-review-category`, {
                specificReviewCategoryId: category.id,
                poolSpecificReviewCategoryId: selectedReviewCategory
            },{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            toast.success('Review category assigned successfully');
            onClose();
        } catch (error) {
            toast.error('Failed to assign review category');
            console.error('Error assigning review category:', error);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                color: 'black'
            }}>
                <Typography variant="h6" sx={{ mb: 2 }} >Assign Pool Category</Typography>
                <FormControl fullWidth>
                    <InputLabel>Select Pool Category</InputLabel>
                    <Select
                        value={selectedReviewCategory}
                        label="Select Review Category"
                        onChange={(e) => setSelectedReviewCategory(e.target.value)}
                    >
                        {reviewCategories.map((reviewCategory) => (
                            <MenuItem key={reviewCategory._id} value={reviewCategory._id}>
                                {reviewCategory.categoryName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" onClick={handleAssign} style={{ marginTop: '1rem' }}>
                    Assign
                </Button>
            </Box>
        </Modal>
    );
};

export default AssignSpecificReviewCategoryToPoolCategoryModal;

