import React, { useState, useEffect } from 'react'
import Circular from '../components/Circular'
import { Typography, Menu, IconButton, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import DataTable from '../components/DataTable'
import axios from 'axios'
import CreateGmail from '../components/CreateGmail'
import ImportGmail from '../components/ImportGmail'
import { useNavigate } from 'react-router-dom'
import EditGmailModal from '../components/EditGmailModal'
import { toast, ToastContainer } from 'react-toastify'
import { CiEdit } from "react-icons/ci";
import { AiTwotoneDelete } from "react-icons/ai"
import { CgMoreVertical } from 'react-icons/cg'
import ConfirmDeleteModal from '../components/ConfirmDeleteModal'
import SearchGmail from '../components/SearchGmail'


const AllGmail = () => {
    const URL = process.env.REACT_APP_BASE_URL
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))
    const [data, setData] = useState([])
    const [render, setrender] = useState(false)
    const navigate = useNavigate()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [delData, setDeleteData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);  // For tracking the current page
    const [totalPages, setTotalPages] = useState(1);    // For tracking the total pages
    const [totalItems, setTotalItems] = useState(1);    // For tracking the total pages
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

    useEffect(() => {
        console.log("use effect called")
        const token = localStorage.getItem('token');    
        if (!token) {
        console.log("No token found, redirecting to login");
        navigate("/login");
        }
    }, [])

    useEffect(() => {
        fetchGmails()
        loadColumnVisibility()

    }, [render])

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentProxyData, setCurrentProxyData] = useState(null);


    const fetchGmails = (page = 1, limit = 100) => {
        let url = `${process.env.REACT_APP_BASE_URL}gmail/allgmail?page=${page}&limit=${limit}`;

        axios.get(url,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setTotalPages(res.data.totalPages);
                setCurrentPage(res.data.currentPage);
                setTotalItems(res.data.totalItems);
                setData(res.data.gmails)
            })
            .catch((error) => { console.log(error) })
    }
    const handleMenuClick = (event, row) => {
        setMenuAnchorEl(event.currentTarget);
        setCurrentRow(row);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setCurrentRow(null);
    };

    const handleOpenEditModal = (gmailData) => {
        setCurrentProxyData(gmailData);
        setIsEditModalOpen(true);
    };

    const handleEdit = () => {
        handleMenuClose();
        handleOpenEditModal(currentRow);
    };


    const handleDeleteConfirm = () => {
        setIsDeleteModalOpen(false);
        handleBulkDelete(delData)

        // deleteGmail(currentRow._id);
    };

    const handleDeleteCancel = () => {
        setIsDeleteModalOpen(false);
    };


    const handleDelete = () => {
        setIsDeleteModalOpen(true);
        handleMenuClose();
    };

    const onAssignToProxy = async (Data) => {
        // console.log("DELETE DATA : ", Data)
    };

    const ConfirmHandleDelete = async (deleteData) => {
        setIsDeleteModalOpen(true);
        setDeleteData(deleteData)
        // handleBulkDelete(deleteData)
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage); // Update the current page
        fetchGmails(newPage); // Fetch new data when the page changes
    };

    const handleBulkDelete = async (deleteData) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}gmail/bulkdelete`, { ids: deleteData },{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (ToasterToggle) { // Check if toaster should be displayed

                toast.success(response.data.message || 'Selected Gmail records deleted successfully');
            }
            setrender(prev => !prev); // Refresh the Gmail list
        } catch (error) {
            if (ToasterToggle) { // Check if toaster should be displayed

                toast.error(error.response.data.message || 'Failed to delete selected Gmail records');
            }
            console.error(error);
        }
    };

    const deleteGmail = (gmailId) => {
        axios.delete(`${process.env.REACT_APP_BASE_URL}gmail/deletegmail/${gmailId}`,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(() => {
                setrender(prev => !prev); // Refresh the Gmail list
                if (ToasterToggle) { // Check if toaster should be displayed

                    toast.success('Gmail deleted successfully');
                }
            })
            .catch((error) => {
                if (ToasterToggle) { // Check if toaster should be displayed

                    toast.error('Failed to delete Gmail')
                }
            }
            );
    };

    const columns = [
        // { field: "_id", headerName: "ID", flex: 1 },
        { field: "gmail", headerName: "Gmail", flex: 1, resizable: true },
        { field: "password", headerName: "Password", flex: 1, resizable: true },
        { field: "recovery_email", headerName: "Recovery Email", flex: 1, resizable: true },
        { field: "secret_key", headerName: "Secret Key", flex: 1, resizable: true },
        { field: "account", headerName: "Account", flex: 1, resizable: true },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <IconButton
                    aria-label="more"
                    onClick={(event) => handleMenuClick(event, params.row)}
                >
                    <CgMoreVertical />
                </IconButton>
            ),
        },



    ]

    const flattenConnectedUsers = data.map((rowM, index) => {
        return {
            id: rowM._id,
            _id: rowM._id,
            gmail: rowM.gmail,
            password: rowM.password,
            phn_no: rowM.phn_no,
            recovery_email: rowM.recovery_email,
            secret_key: rowM.secret_key,
            account: rowM.account?.profileName || "NAN"
        };
    });

    const setSearchGmail = (gmailData) => {
        setTotalPages(gmailData.totalPages);  // Set the total pages from the API
        setCurrentPage(gmailData.currentPage);  // Set the current page from the API
        setTotalItems(gmailData.totalItems); // Set the total number of items

        const flattenConnectedGmails = gmailData.gmails.map((rowM, index) => {
            return {
                id: rowM._id,
                _id: rowM._id,
                gmail: rowM.gmail,
                password: rowM.password,
                phn_no: rowM.phn_no,
                recovery_email: rowM.recovery_email,
                secret_key: rowM.secret_key,
                account: rowM.account?.profileName || "NAN"
            };
        });

        setData(flattenConnectedGmails);
    }

    const loadColumnVisibility = () => {
        const storedVisibility = localStorage.getItem('gmailColumnVisibility');
        if (storedVisibility) {
            setColumnVisibilityModel(JSON.parse(storedVisibility));
        }
    };

    const saveColumnVisibility = (model) => {
        localStorage.setItem('gmailColumnVisibility', JSON.stringify(model));
        setColumnVisibilityModel(model);
    };

    return (
        <div style={{ width: "99%" }}>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <EditGmailModal
                open={isEditModalOpen}
                handleClose={() => setIsEditModalOpen(false)}
                gmailData={currentProxyData}
                setrender={setrender}
            />
            <ConfirmDeleteModal
                isOpen={isDeleteModalOpen}
                onClose={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
                message="Are you sure you want to delete this record?"
            />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5"> Gmail </Typography>

                <CreateGmail render={render} setrender={setrender} />

            </div>
            <SearchGmail setSearchGmail={setSearchGmail} />
            {data.length > 0 ? <> <DataTable rows={flattenConnectedUsers} columns={columns} onDeleteSelected={ConfirmHandleDelete} onAssignToProxy={onAssignToProxy} showAssignToCategory={false} handlePageChange={handlePageChange} currentPage={currentPage} totalPages={totalPages} totalItems={totalItems} saveColumnVisibility={saveColumnVisibility} columnVisibilityModel={columnVisibilityModel} />
                <Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleEdit}>
                        <ListItemIcon>
                            <CiEdit fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                    </MenuItem>
                    {/* <MenuItem onClick={handleDelete}>
                        <ListItemIcon>
                            <AiTwotoneDelete fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem> */}
                </Menu>

            </> : <>
                <Circular />
            </>}
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}>
                <ImportGmail render={render} setrender={setrender} />
            </div>


        </div>
    )
}

export default AllGmail

