import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, TextField, Modal } from '@mui/material';
import { toast } from 'react-toastify';
import axios from "axios";




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ChangeCategoryName = ({ isOpen, onClose, category, render, setrender }) => {
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))
    const [formData, setFormData] = useState({
        name: '',
    });

    useEffect(() => {
        if (category) {
            setFormData({ ...category });
        }
    }, [category]);

    const handleSave = () => {
        // Validate form data
        if (!formData.name) {
            if (ToasterToggle) {
                toast.error('Name Field Should not be empty');
            }
            return;
        }

        axios.put(`${process.env.REACT_APP_BASE_URL}category/update/${category.id}`, formData ,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                if (ToasterToggle) {
                    toast.success('Proxy updated successfully');
                }
                setrender(!render);
                onClose();
            })
            .catch((error) => {
                if (ToasterToggle) {

                    toast.error('Error updating proxy');
                }
                console.error('Error updating proxy:', error);
            });
    };

    return (
        <>
            <Modal
                open={isOpen}
                onClose={onClose}
                aria-labelledby="edit-proxy-modal-title"
                aria-describedby="edit-proxy-modal-description"
            >

                <Box sx={style}>
                    <Typography id="edit-proxy-modal-title" variant="h6" component="h2" style={{ color: "black" }}>
                        Change Category Name
                    </Typography>

                    <TextField label="CategoryName" variant="outlined" fullWidth margin="normal" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={onClose} sx={{ mr: 1 }}>Cancel</Button>
                        <Button onClick={handleSave} variant="contained">Save</Button>
                    </Box>
                </Box>
            </Modal>
        </>);
};

export default ChangeCategoryName;


