import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const ChangeProxyModal = ({ open, onClose, account, setRender }) => {
    const [proxies, setProxies] = useState([]);
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))
    const [selectedProxyId, setSelectedProxyId] = useState('');

    useEffect(() => {
        // Fetch available proxies


        axios.get(`${process.env.REACT_APP_BASE_URL}proxy/matchproxies/${account.region}`,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => {
                setProxies(response.data);
            })
            .catch(error => console.log(error));
    }, [open]);

    const handleChangeProxy = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}proxy/changeProxy`,{
            accountId: account._id,
            proxyCategoryId: selectedProxyId,
            }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
            })
            .then(response => {
                if (ToasterToggle) {

                    toast.success(response.data.message || 'Proxy changed successfully');
                }
                setRender(prev => !prev);
                onClose();
            })
            .catch(error => {
                if (ToasterToggle) {

                    toast.error(error.response.data.message || 'Failed to change proxy');
                }
                console.error(error);
            });
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2
            }}>
                <Typography variant="h6" sx={{ color: "black", marginBottom: "1rem" }}>Change Proxy</Typography>
                <FormControl fullWidth>
                    <InputLabel id="proxy-select-label" >Select New Proxy</InputLabel>
                    <Select
                        labelId="proxy-select-label"
                        value={selectedProxyId}
                        onChange={(e) => setSelectedProxyId(e.target.value)}
                    >
                        {proxies.map((proxy) => (
                            <MenuItem key={proxy._id} value={proxy._id}>{proxy.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button onClick={handleChangeProxy}>Change Proxy</Button>
            </Box>
        </Modal>
    );
};

export default ChangeProxyModal;