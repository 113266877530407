import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography, FormControl, Select, MenuItem, InputLabel, CircularProgress } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const AssignPBSLink = ({ open, onClose, reviewId, render, setRender }) => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [links, setLinks] = useState([]);
    const [selectedLink, setSelectedLink] = useState('');
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [loadingLinks, setLoadingLinks] = useState(false);

    const URL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        if (open) {
            fetchCategories();
        }
    }, [open]);

    const fetchCategories = async () => {
        setLoadingCategories(true);
        try {
            const response = await axios.get(`${URL}pbs-category/all`,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setCategories(response.data);
        } catch (error) {
            toast.error('Failed to fetch categories');
            console.error('Error fetching categories:', error);
        } finally {
            setLoadingCategories(false);
        }
    };

    const fetchLinks = async (categoryId) => {
        setLoadingLinks(true);
        try {
            const response = await axios.get(`${URL}pbs-category/${categoryId}/dropdown-links`,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setLinks(response.data);
        } catch (error) {
            toast.error('Failed to fetch GMB Links');
            console.error('Error fetching GMB Links:', error);
        } finally {
            setLoadingLinks(false);
        }
    };

    const handleAssign = async () => {
        if (!selectedLink) {
            toast.error('Please select a GMB Link');
            return;
        }
        try {
            const response = await axios.put(`${URL}specific-review/${reviewId}/assign-pbslink`, {
                pbsLinkId: selectedLink,
            },{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            toast.success(response.data.message || 'GMB Link assigned successfully');
            setRender(!render);
            onClose();
        } catch (error) {
            toast.error('Failed to assign GMB Link');
            console.error('Error assigning GMB Link:', error);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Typography variant="h6" sx={{ color: 'black' }}>
                    Assign GMB Link to Review
                </Typography>
                <FormControl fullWidth>
                    <InputLabel>Select Category</InputLabel>
                    <Select
                        value={selectedCategory}
                        label="Select Category"
                        onChange={(e) => {
                            setSelectedCategory(e.target.value);
                            fetchLinks(e.target.value); // Fetch links when category changes
                        }}
                        disabled={loadingCategories}
                    >
                        {loadingCategories ? (
                            <MenuItem disabled>
                                <CircularProgress size={24} />
                            </MenuItem>
                        ) : (
                            categories.map((category) => (
                                <MenuItem key={category._id} value={category._id}>
                                    {category.categoryName}
                                </MenuItem>
                            ))
                        )}
                    </Select>
                </FormControl>
                {selectedCategory && (
                    <FormControl fullWidth>
                        <InputLabel>Select GMB Link</InputLabel>
                        <Select
                            value={selectedLink}
                            label="Select GMB Link"
                            onChange={(e) => setSelectedLink(e.target.value)}
                            disabled={loadingLinks}
                        >
                            {loadingLinks ? (
                                <MenuItem disabled>
                                    <CircularProgress size={24} />
                                </MenuItem>
                            ) : (
                                links.map((link) => (
                                    <MenuItem key={link._id} value={link._id}>
                                        {link.linkName}
                                    </MenuItem>
                                ))
                            )}
                        </Select>
                    </FormControl>
                )}
                <Button
                    variant="contained"
                    onClick={handleAssign}
                    disabled={!selectedLink}
                >
                    Assign PBSLink
                </Button>
            </Box>
        </Modal>
    );
};

export default AssignPBSLink;
