import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import { TextField, InputAdornment, CircularProgress, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchQuestion = ({ setSearchQuestion }) => {
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [noResults, setNoResults] = useState(false);

    const limit = 100;

    const fetchQuestions = async (searchQuery, page) => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}question/search`, {
                params: {
                    query: searchQuery,
                    page: page,
                    limit: limit
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            setSearchQuestion(response.data); // Set the fetched reviews in the parent component
            if (response.data.questions.length === 0) {
                setNoResults(true);
            } else {
                setNoResults(false);
            }

            setLoading(false);
        } catch (error) {
            console.error('Error fetching questions:', error);
            setLoading(false);
        }
    };

    const debouncedSearch = useCallback(debounce((searchQuery) => {
        if (searchQuery) {
            fetchQuestions(searchQuery, 1);
        }
    }, 500), []);

    useEffect(() => {
        if (query) {
            debouncedSearch(query);
        }
    }, [query, debouncedSearch]);

    const handlePageChange = (newPage) => {
        fetchQuestions(query, newPage);
    };

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', my: 2, gap: 2 }}>
            {/* Search Input */}
            <TextField
                variant="outlined"
                label="Search by Question Text or Location"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                sx={{
                    width: '300px',
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#e0e0e0',
                        },
                        '&:hover fieldset': {
                            borderColor: '#bdbdbd',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#9e9e9e',
                        },
                    },
                    '& .MuiInputBase-input': {
                        color: 'white',
                    },
                    '& .MuiInputLabel-root': {
                        color: '#757575',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: '#616161',
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon sx={{ color: 'white' }} />
                        </InputAdornment>
                    )
                }}
            />

            {/* Loading Indicator */}
            {loading && (
                <CircularProgress />
            )}

            {/* No results message */}
            {!loading && noResults && <p>No results found</p>}
        </Box>
    );
};

export default SearchQuestion;

