import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const ProxyAssignCategoryModal = ({ open, onClose, proxy, onSuccess }) => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))
    useEffect(() => {
        if (open) {
            axios.get(`${process.env.REACT_APP_BASE_URL}proxycategory/all`,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then((response) => {
                    setCategories(response.data);
                })
                .catch((error) => console.error("Failed to fetch categories:", error));
        }
    }, [open]);

    const handleAssign = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}proxycategory/assign`, {
            proxyId: proxy._id,
            categoryId: selectedCategory
        },{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(() => {
            if (ToasterToggle) { // Check if toaster should be displayed

                toast.success('Proxy assigned to category successfully');
            }
            onSuccess && onSuccess();
            onClose();
        }).catch((error) => {
            if (ToasterToggle) { // Check if toaster should be displayed

                toast.error(error.response.data.message || 'Failed to assign proxy to category');
            }
            console.error(error);
        });
    };
    // console.log("MULTIPLE PROXIES : ", multipleProxy)
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                borderRadius: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}>
                <Typography variant="h6" sx={{ color: "black" }}>Assign Proxy to Category</Typography>
                <FormControl fullWidth>
                    <InputLabel>Select Category</InputLabel>
                    <Select
                        value={selectedCategory}
                        label="Select Category"
                        onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                        {categories?.map((category) => (
                            <MenuItem key={category._id} value={category._id}>{category.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" onClick={handleAssign}>Assign</Button>
            </Box>
        </Modal>
    );
};

export default ProxyAssignCategoryModal;
