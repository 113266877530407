// EditGmailModal.js
import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, TextField, Modal } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const EditGmailModal = ({ open, handleClose, gmailData, setrender }) => {
    const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))
    const [formData, setFormData] = useState({
        gmail: '',
        phn_no: '',
        password: '',
        recovery_email: '',
        secret_key: ''
    });

    useEffect(() => {
        if (gmailData) {
            setFormData({ ...gmailData });
        }
    }, [gmailData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleUpdate = () => {
        if (Object.values(formData).some(field => field === '')) {
            if (ToasterToggle) {

                toast.error('All fields must be filled out');
            }
            return;
        }

        const URL = `${process.env.REACT_APP_BASE_URL}gmail/editgmail/${gmailData._id}`;
        axios.put(URL, formData,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                if (ToasterToggle) {

                    toast.success('Gmail updated successfully');
                }
                setrender(prev => !prev);
                handleClose();
            })
            .catch((error) => {
                if (ToasterToggle) {

                    toast.error('Failed to update Gmail');
                }
                console.error(error);
            });
    };

    return (<>
        <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />

        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Typography variant="h6" color={"black"}>Edit Gmail</Typography>
                <TextField
                    label="Gmail"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.gmail}
                    onChange={(e) => setFormData({ ...formData, gmail: e.target.value })}
                />
                <TextField
                    label="Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.password}
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                />
                <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.phn_no}
                    onChange={(e) => setFormData({ ...formData, phn_no: e.target.value })}
                />
                <TextField
                    label="Recovery Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.recovery_email}
                    onChange={(e) => setFormData({ ...formData, recovery_email: e.target.value })}
                />
                <TextField
                    label="Phone Id"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.secret_key}
                    onChange={(e) => setFormData({ ...formData, secret_key: e.target.value })}
                />

                <Button variant="outlined" color="error" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="outlined" color="success" onClick={handleUpdate}>
                    Save
                </Button>
                {/* <Button onClick={handleUpdate}>Save Changes</Button> */}
            </Box>
        </Modal>
    </>);
};

export default EditGmailModal;
