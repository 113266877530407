import React, { useEffect, useState } from 'react'
import LinksForm from '../components/LinksForm'
import ImportLinks from '../components/ImportLinks'
import AllLinks from './AllLinks'
import { Typography } from '@mui/material'
import axios from 'axios'
import SearchLink from '../components/link/SearchLink'

const Links = () => {
    const [render, setRender] = useState(false)
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});


    useEffect(() => {
        fetchLinks(page);
        loadColumnVisibility();
    }, [render]);

    const fetchLinks = async (pageNumber) => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}link/all-links`, {
                params: {
                    page: pageNumber,
                    limit: 100,
                },
                headers : {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setLinks(response.data.links);
            setTotalPages(response.data.totalPages);
            setTotalItems(response.data.totalItems);
            setPage(response.data.currentPage)
        } catch (error) {
            console.error('Failed to fetch links:', error);
        }
        setLoading(false);
    };

    const flattenLinks = links.map((rowM, index) => {
        return {
            id: rowM._id,
            _id: rowM._id,
            link: rowM.link || rowM.links,
        };
    });


    const columns = [
        { field: 'id', headerName: 'ID', flex: 2 },
        { field: 'link', headerName: 'Link', flex: 2 },
    ];

    const handlePageChange = (newPage) => {
        setPage(newPage);
        fetchLinks(newPage);
    };

    const loadColumnVisibility = () => {
        const storedVisibility = localStorage.getItem('linksColumnVisibility');
        if (storedVisibility) {
            setColumnVisibilityModel(JSON.parse(storedVisibility));
        }
    };

    const saveColumnVisibility = (model) => {
        localStorage.setItem('linksColumnVisibility', JSON.stringify(model));
        setColumnVisibilityModel(model);
    };

    const setSearchLinks = (linksData) => {
        console.log("LINKS DATA : ", linksData)
        setTotalItems(linksData.totalItems);
        setLinks(linksData.links)
    }

    return (
        <div>
            <Typography variant="h3"> Links </Typography>
            <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "2rem" }}>
                <LinksForm render={render} setRender={setRender} />
            </div>
            <SearchLink setSearchLink={setSearchLinks} />
            <AllLinks render={render} setRender={setRender} Page={page} totalItems={totalItems} rows={flattenLinks} columns={columns} handlePageChange={handlePageChange} loading={loading} saveColumnVisibility={saveColumnVisibility} columnVisibilityModel={columnVisibilityModel} />
            <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "2rem" }}>
                <ImportLinks />
            </div>
        </div>
    )
}

export default Links

