// AttachModal.js
import React, { useEffect, useState } from 'react';
import { Modal, Box, Button, Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';


const AttachModal = ({ open, onClose, onAttach, selectedRow }) => {
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const [data, setData] = useState([]);
  const ToasterToggle = JSON.parse(localStorage.getItem("Toaster"))

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}gmail/allwithoutref`)
      .then((res) => {
        setData(res.data.gmails);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleAttach = async () => {
    if (selectedAccountId) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}gmail/attachgmail`,{
          accountId: selectedRow._id,
          gmailId: selectedAccountId
        }, {
          headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
          }});

        if (response.status === 200) {
          onAttach(selectedAccountId);
          onClose();
        }
        if (ToasterToggle) {
          toast.success(response.data.message || "Gmail attached successfully")
        }
      } catch (error) {
        if (ToasterToggle) {
          toast.error(error.response.data.message || "Gmail attached Failed")
        }
        console.error('Error attaching Gmail to Account:', error);
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2, color: "black" }}>
          Attach Gmail
        </Typography>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel id="gmail-dropdown">Select Gmail</InputLabel>
          <Select
            labelId="gmail-dropdown"
            value={selectedAccountId}
            label="Select Gmail"
            onChange={(e) => setSelectedAccountId(e.target.value)}
            sx={{
              '.MuiOutlinedInput-notchedOutline': { borderColor: 'grey' },
              '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'primary.main' },
              color: 'black', // Adjust text color as needed
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  bgcolor: 'white', // Dropdown background color
                  '& .MuiMenuItem-root': {
                    color: 'black', // MenuItem text color
                    '&:hover': {
                      bgcolor: 'grey.100', // MenuItem hover background color
                    },
                  },
                },
              },
            }}
          >
            {data.map((val) => (
              <MenuItem key={val._id} value={val._id}>{val.gmail}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handleAttach} sx={{ backgroundColor: 'primary.main', '&:hover': { backgroundColor: 'primary.dark' } }}>
          Attach
        </Button>
      </Box>
    </Modal>
  );
};

export default AttachModal;
